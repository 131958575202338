// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";
import "./Carousel.scss";

export default function Carousel() {
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <div className="slider-container">
      <div className="swiper-container card-slider h-100 ">
        <div className="swiper-button-prev" />
        <div className="swiper-wrapper">
          <Swiper
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Autoplay, Navigation]}
            spaceBetween={30}
            slidesPerView={width > 500 ? 2 : 1}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            <div>
              <SwiperSlide className="swiper-slide">
                <div className="card" style={{ minHeight: "530px" }}>
                  <div className="card-body">
                    <p className="testimonial-text">
                      "I admire your efforts in making learning easier and more
                      enjoyable for the people in medicine. It certainly is a
                      tool unlike any others, I believe it has the capacity to
                      motivate students to learn in an engaging and playful
                      environment. It can help students and professionals alike
                      to enjoy different subject areas in medicine in a game
                      like manner. Congratulations on a job being very well
                      done. I shall be recommending your site to all in the
                      field of medicine. Keep it up."
                    </p>
                    <div className="details">
                      <img
                        className="testimonial-image"
                        src="images/testimonial-1.jpg"
                        alt="alternative"
                      />
                      <div className="text">
                        <div className="testimonial-author">
                          Prof Dr Hemang Dixit
                        </div>
                        <div className="occupation">
                          Ex Dean, IOM <br />
                          Ex Principal KMC
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="card" style={{ minHeight: "530px" }}>
                  <div className="card-body">
                    <p className="testimonial-text">
                      "When I came to know about Medipuzzle, I immediately
                      downloaded it and started playing it! It's fun and
                      educational at the same time. Very useful to test your
                      pharmacology knowledge (which I thought I was good at,
                      until I failed quite a many times in the game!) and
                      improve it! "<br /> 🙂🙂
                    </p>
                    <div className="details" style={{ marginTop: 130 }}>
                      <img
                        className="testimonial-image"
                        src="images/testimonial-2.jpg"
                        alt="alternative"
                      />
                      <div className="text">
                        <div className="testimonial-author">
                          Prof Dr Abhinav Vaidya{" "}
                        </div>
                        <div className="occupation">
                          Kathmandu Medical College
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="card" style={{ minHeight: "530px" }}>
                  <div className="card-body">
                    <p className="testimonial-text">
                      "Pharmacology has always been a nightmare for every
                      medicos, completing a chapter forgetting the previous ones
                      and the cycle goes on. In this world of smart phones, I
                      think interactive applications like medipuzzle is one of
                      the fun way to overcome the nightmare and brush up our
                      knowledge. Kudos to the team who came up with Medipuzzle
                      that i am sure is going to be very useful to all the
                      medical students and practitioners."
                    </p>
                    <div className="details" style={{ marginTop: 55 }}>
                      <img
                        className="testimonial-image"
                        src="images/testimonial-3.jpg"
                        alt="alternative"
                      />
                      <div className="text">
                        <div className="testimonial-author">
                          Dr. Subham Kumar Rouniyar
                        </div>
                        <div className="occupation">
                          MBBS (KMCTH)
                          <br />
                          MO at Mulpani PHCC, Dingla, Bhojpur
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="card" style={{ minHeight: "530px" }}>
                  <div className="card-body">
                    <p className="testimonial-text">
                      " What we learn with pleasure, we never forget." -Alfred
                      Mercier During my interaction with seniors, juniors and
                      batchmates at Med School, pharmacology used to be one of
                      the toughest subjects for most of us. Reason being new
                      words and terminology. Our brain has been designed in such
                      a way that we tend to love playing and entertaining
                      ourselves. So why not play and learn easier and faster?
                      Medipuzzle is an appropriate tool for learning
                      pharmacology with fun.
                    </p>
                    <div className="details" style={{ marginTop: 20 }}>
                      <img
                        className="testimonial-image"
                        src="images/testimonial-5.jpg"
                        alt="alternative"
                      />
                      <div className="text">
                        <div className="testimonial-author">
                          Dr. Bikash Sitaula
                        </div>
                        <div className="occupation">
                          Medical Officer <br />
                          KDC, Mero Doctor
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="card" style={{ minHeight: "530px" }}>
                  <div className="card-body">
                    <p className="testimonial-text">
                      Pharmacology, a subject which is exciting and at the same
                      time so tedious to read and remember because of the
                      virtually infinite number of drugs and classifications.
                      And that is why the app Medipuzzle has a special place.It
                      provides an interesting and effortless way to learn and
                      remember pharmacological concepts which every medical
                      student needs to have. Simple yet a great app .😍
                    </p>
                    <div className="details" style={{ marginTop: 55 }}>
                      <img
                        className="testimonial-image"
                        src="images/testimonial-4.jpg"
                        alt="alternative"
                      />
                      <div className="text">
                        <div className="testimonial-author">
                          Gehendra Mukhiya
                        </div>
                        <div className="occupation">
                          MBBS Final year <br />
                          Kathmandu Medical College
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
        <div className="swiper-button-next" />
      </div>
    </div>
  );
}
