import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./Components/Header.jsx";
import BaseRoute from "./BaseRoute/BaseRoute.jsx";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<BaseRoute />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
