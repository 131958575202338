import './Terms.scss';

export default function Terms() {
  return (
    <div className="container terms">
      <header>
        <h3>Terms & Conditions</h3>
        <p className="terms__date">Last updated: 13th December, 2021</p>
      </header>

      <p className="terms__notice">
        Please read these Terms of Service ("Terms", "Terms of Service")
        carefully before using the https://www.medipuzzle.com website and the
        MediPuzzle mobile application (the "Service") operated by MediPuzzle
        ("us", "we", or "our"). Your access to and use of the Service is
        conditioned on your acceptance of and compliance with these Terms. These
        Terms apply to all visitors, users and others who access or use the
        Service. By accessing or using the Service you agree to be bound by
        these Terms. If you disagree with any part of the terms then you may not
        access the service.
      </p>

      <div className="terms__list">
        <h5>1. Availability, Errors and Inaccuracies</h5>
        <p>
          We are constantly updating our offerings of products and services on
          the Service. The products or services available on our Service may
          experience delays in updating information on the Service and in our
          advertising on other web sites. You expressly agree that any such
          offer of a product or service does not constitute a legal offer
          capable of attracting legal consequences.
        </p>
        <p>
          We cannot and do not guarantee the accuracy or completeness of any
          information, including prices, product images, specifications,
          availability, and services. We reserve the right to change or update
          information and to correct errors, inaccuracies, or omissions at any
          time without prior notice. Section "Availability, Errors and
          Inaccuracies" is without prejudice to existing statutory rights.
        </p>
      </div>

      <div className="terms__list">
        <h5>2. Content</h5>
        <p>
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content that you
          post to the Service, including its legality, reliability, and
          appropriateness.
        </p>
        <p>
          By posting Content to the Service, you grant us the right and license
          to use, modify, publicly perform, publicly display, reproduce, and
          distribute such Content on and through the Service. You retain any and
          all of your rights to any Content you submit, post or display on or
          through the Service and you are responsible for protecting those
          rights. You agree that this license includes the right for us to make
          your Content available to other users of the Service, who may also use
          your Content subject to these Terms. You represent and warrant that:
        </p>
        <ul>
          <li>
            The Content is yours (you own it) or you have the right to use it
            and grant us the rights and license as provided in these Terms, and
          </li>
          <li>
            The posting of your Content on or through the Service does not
            violate the privacy rights, publicity rights, copyrights, contract
            rights or any other rights of any person.
          </li>
        </ul>
      </div>

      <div className="terms__list">
        <h5>3. Accounts</h5>
        <p>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service. You are responsible for
          safeguarding the password that you use to access the Service and for
          any activities or actions under your password, whether your password
          is with our Service or a third-party service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account. You may not use as a username the
          name of another person or entity or that is not lawfully available for
          use, a name or trade mark that is subject to any rights of another
          person or entity other than you without appropriate authorization, or
          a name that is otherwise offensive, vulgar or obscene. You expressly
          agree that we cannot be held liable for any loss or damage arising out
          of any misrepresentations you make in this regard.
        </p>
      </div>

      <div className="terms__list">
        <h5>4. Copyright Policy</h5>
        <p>
          We respect the intellectual property rights of others. It is our
          policy to respond to any claim that Content posted on the Service
          infringes the copyright or other intellectual property infringement
          ("Infringement") of any person. If you are a copyright owner, or
          authorized on behalf of one, and you believe that the copyrighted work
          has been copied in a way that constitutes copyright infringement that
          is taking place through the Service, you must submit your notice in
          writing to the attention of "Copyright Infringement" at
          ayata.inc@gmail.com and include in your notice a detailed description
          of the alleged Infringement. In cases where there are conflicting
          claims to the ownership of Copyright, it is our policy to air on the
          side of removing materials from our Service.
        </p>
        <p>
          We, at MediPuzzle, are committed to ensuring that the material
          displayed on our Service is lawful and in accordance with our
          country’s Copyright Law. To help us deal with your complaint as
          quickly as possible, please include the following information in your
          correspondence and mark it as "URGENT":
        </p>
        <ol>
          <li>
            Your contact details - including your name, email address and
            daytime telephone number.
          </li>
          <li>
            Identify the material in question - please include sufficient detail
            to enable us to identify the material complained of.
          </li>
          <li>The reasons for your objection.</li>
        </ol>

        <p>
          We will review each objection on its merits and pending our inquiries,
          may remove or disable access to the relevant material from our
          Service. Where appropriate, you should include information relating to
          the status of the material in question (i.e. Where you hold a
          Trademark, or own the Copyright to an image). This will enable us to
          deal with your request promptly. You can contact our liaison officer
          via email at ayata.inc@gmail.com
        </p>
      </div>

      <div className="terms__list">
        <h5>5. Intellectual Property </h5>
        <p>
          The Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of MediPuzzle and its licensors. Our trademarks and trade
          dress may not be used in connection with any product or service
          without the prior written consent of MediPuzzle.
        </p>
        <p>
          When you upload content, you give to MediPuzzle a worldwide,
          non-exclusive, royalty-free, transferable licence (with right to
          sub-license) to use, reproduce, distribute, prepare derivative works
          of, display, and perform that Content in connection with the provision
          of the Service and otherwise in connection with the provision of the
          Service and MediPuzzle business.
        </p>
      </div>

      <div className="terms__list">
        <h5>6. Links to Other Web Sites</h5>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by MediPuzzle. MediPuzzle has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that MediPuzzle shall not be responsible
          or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on
          any such content, goods or services available on or through any such
          web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
      </div>

      <div className="terms__list">
        <h5>7. Termination</h5>
        <p>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms. Upon termination, your right to
          use the Service will immediately cease. If you wish to terminate your
          account, you may simply discontinue using the Service. Termination of
          account may also happen if the same user account is used
          simultaneously in two different locations at the same time, following
          initial warnings.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
      </div>

      <div className="terms__list">
        <h5>8. Indemnification</h5>
        <p>
          You agree to defend, indemnify and hold harmless MediPuzzle and its
          licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password; b) a breach of these Terms, or
          c) Content posted on the Service.
        </p>
      </div>

      <div className="terms__list">
        <h5>9. Limitation of Liability</h5>
        <p>
          In no event shall MediPuzzle, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>
      </div>

      <div className="terms__list">
        <h5>10. Disclaimer</h5>
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>
        <p>
          MediPuzzle its subsidiaries, affiliates, and its licensors do not
          warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </p>
      </div>

      <div className="terms__list">
        <h5>11. Exclusions</h5>
        <p>
          Without limiting the generality of the foregoing and notwithstanding
          any other provision of these terms, under no circumstances will
          MediPuzzle ever be liable to you or any other person for any indirect,
          incidental, consequential, special, punitive or exemplary loss or
          damage arising from, connected with, or relating to your use of the
          Service, these Terms, the subject matter of these Terms, the
          termination of these Terms or otherwise, including but not limited to
          personal injury, loss of data, business, markets, savings, income,
          profits, use, production, reputation or goodwill, anticipated or
          otherwise, or economic loss, under any theory of liability (whether in
          contract, tort, strict liability or any other theory or law or
          equity), regardless of any negligence or other fault or wrongdoing
          (including without limitation gross negligence and fundamental breach)
          by MediPuzzle or any person for whom MediPuzzle is responsible, and
          even if MediPuzzle has been advised of the possibility of such loss or
          damage being incurred.
        </p>
      </div>

      <div className="terms__list">
        <h5>12. Governing Law</h5>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of Nepal, without regard to its conflict of law provisions. Our
          failure to enforce any right or provision of these Terms will not be
          considered a waiver of those rights. If any provision of these Terms
          is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
      </div>

      <div className="terms__list">
        <h5>13. Changes</h5>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, you must stop using the service.
        </p>
      </div>

      <div className="terms__list">
        <h5>14. Privacy Policy and Cookie Policy</h5>
        <p>
          Please refer to our Privacy Policy (which includes our Cookies
          Policy). You agree that they constitute part of these terms. You must
          read our Privacy Policy and Cookies Policy before you use the Service.
        </p>
      </div>

      <div>
        <h5>Contact Us</h5>
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
    </div>
  );
}
