import './NotFound.scss'

import NotFoundImage from '../assets/images/404.svg'

export default function NotFound() {
  return (
    <div className="error-container">
      <img src={NotFoundImage} alt="404" className="error-image" />
      <p className="error-description">
        The page you are looking for doesn't exist.
      </p>
    </div>
  );
}
