import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../Components/Toast";

const AccountDeletionForm = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    setTimeout(() => {
      navigate("/*");
      setIsSubmitting(false);
    }, 4000);
  };

  return (
    <div
      style={{
        padding: "100px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "relative",
      }}>
      <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
        Request for Account Deletion
      </h2>
      <p>
        Send a request to delete your account and personally identifiable
        information (Medipuzzle) that is stored on our system. You will receive
        an email to verify your request. Once the request is verified we will
        take care of deleting your Medipuzzle. If you just want to check what
        Medipuzzle we have stored, you can{" "}
        <u className="text-primary cursor-pointer">request your data</u>.
      </p>
      <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
        Note: Your request for account deletion will be fulfilled within 3 days.
      </h5>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
        <label
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            fontSize: "12px",
            color: "gray",
          }}>
          <h5 style={{ fontSize: "16px", fontWeight: "bold" }}>
            Email or Username<span className="text-danger">*</span>
          </h5>
          <input
            style={{
              height: "40px",
              border: "none",
              backgroundColor: "whitesmoke",
              paddingLeft: "16px",
              borderRadius: "6px",
            }}
            type="text"
            placeholder="Enter you email or username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
          }}>
          <button
            disabled={isSubmitting}
            style={{
              backgroundColor: "#496aaa",
              border: "1px solid #496aaa",
              padding: "2px 12px",
              cursor: `${isSubmitting ? "not-allowed" : "pointer"}`,
              borderRadius: "4px",
              color: "white",
            }}
            type="button"
            onClick={() => navigate(-1)}>
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            style={{
              backgroundColor: "#496aaa",
              border: "1px solid #496aaa",
              padding: "2px 12px",
              cursor: `${isSubmitting ? "not-allowed" : "pointer"}`,
              borderRadius: "4px",
              color: "white",
            }}
            type="submit">
            Submit
          </button>
        </div>
      </form>
      {isSubmitting && <Toast message="Your account will be deleted soon" />}
    </div>
  );
};

export default AccountDeletionForm;
