import React from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";

import BasePage from "../pages/BasePage";
import Home from "../pages/Home";
import Blogs from "../pages/Blogs";
import BlogPageView from "../pages/BlogPageView";
import NotFound from "../pages/NotFound";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Downloads from "../pages/Downloads";
import AccountDeletionForm from "../pages/AccountDelete";

export default function BaseRoute() {
  return (
    <Routes>
      <Route element={<BasePage />}>
        <Route index element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:blog_id" element={<BlogPageView />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/delete-account" element={<AccountDeletionForm />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
