import React from "react";
import Carousel from "../Components/Carousel";

export default function Home() {
  return (
    <>
      <header id="header" className="header" style={{ paddingTop: "10.75rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h1 className="h1-large">
                  Now is the time to start learning by playing
                </h1>
                <p className="p-large">
                  Medipuzzle is a game-based learning platform for Medical
                  Education that makes learning online easier
                </p>
              </div>
              <div className="row">
                <div className="col-6 ">
                  <a
                    className="d-block"
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.ayata.medipuzzle&hl=en&gl=US"
                  >
                    <img
                      className="w-75 my-3   "
                      src="./images/googleplay.png"
                      alt="googleplay"
                    />
                  </a>
                </div>
                <div className="col-6">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/np/app/medipuzzle/id1595456309"
                  >
                    <img
                      className="w-75 my-2 "
                      src="./images/appstore.png"
                      alt="appstore"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src="images/header.png"
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="details" className="basic-2" style={{ paddingTop: 120 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="intro">
                <h2>Supercharge your brain with Innovative Games</h2>
                <p>
                  Don't just focus on getting knowledge into your brain, the
                  essence is in bringing it out when needed, and games are good
                  at it. Say no to brain freezes with Medipuzzle. Harness the
                  power of games to conquer medicine NOW!
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "-60px" }}>
            <div className="col-lg-7">
              <div className="image-container">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/Flashcard.gif"
                  style={{ maxWidth: "80%" }}
                  alt="alternative"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="text-container">
                <div className="section-title">Game 1</div>
                <h2>Flashcard</h2>
                <p>
                  Ace your medical exams with our interactive flashcard games!" We all have seen flashcards since kids "A" for "apple" right. 
                  In flashcards you see the question first, a tap on the screen displays the answer. Rate the answer to move on to the next card. 
                  Alternatively you can press the lazy mode to put it into podcast mode and listen to it. 
                  Works best when you are done reading, but your mind is still in learning mode.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="basic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="text-container">
                <div className="section-title">Game 2</div>
                <h2>Hangman</h2>
                <p>
                  Hangman is a game many of us have played during childhood. It
                  is a supply response type of game of basic level. The hangman
                  game begins with a question, where players guess the answer, a
                  single letter at a time. Player wins if he/she guesses the
                  letters right and loses a life if he/she guesses 6 letters
                  wrong. The game ends in 90 seconds. The game comes with 3
                  lives, 5 skips and 7 hints, which you get back for guessing
                  the word correctly without making any mistakes.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="image-container">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/Hangman.gif"
                  style={{ maxWidth: "80%" }}
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="basic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="image-container">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/recall.gif"
                  style={{ maxWidth: "100%" }}
                  alt="alternative"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-container">
                <div className="section-title">Game 3</div>
                <h2>Quick Recall</h2>
                <p>
                  Quick Recall is a kind of select response game quite like
                  MCQs. This game displays around 20 options and you choose the
                  best answer from the list. It could be of single best option
                  or multiple best option type denoted clearly in the game. It
                  is also a timed game and the game ends in stipulated time. It
                  comes with 5 hints and next button options.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="details"
        className="basic-2"
        style={{ paddingTop: 120, paddingBottom: 60 }}
      >
        <div className="container">
          <div className="row mobile-reverse" style={{ marginTop: "-60px" }}>
            <div className="col-lg-7" style={{ marginTop: "-40px"}}>
              <div className="text-container">
                <div className="section-title">Game 4</div>
                <h2>Rapid-fire</h2>
                <p>
                  Rapid-fire game is a game that mimics the actual viva voce
                  exams. Players are rapidly fired one question after another as
                  in VIVA exams. It is a supply response type of game and the
                  players are expected to type the answer. It is timed for 2
                  minutes and you answer as many questions as possible in the
                  time frame to get the highest score. It comes with 5 skips and
                  this can also be redeemed by guessing the words correctly
                  without any mistakes.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="image-container">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/rapidfire.gif"
                  style={{ maxWidth: "100%" }}
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="basic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="image-container d-flex justify-content-center">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/MCQs.gif"
                  style={{ maxWidth: "60%" }}
                  alt="alternative"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-container">
                <div className="section-title">Game 5</div>
                <h2>Multiple Choice Questions</h2>
                <p>
                MCQ is a kind of select response game. The module displays around 
                4 multiple options and you choose the best answer from the list. 
                It could be of single best option or multiple best option type. It 
                is also a timed game and the game ends in stipulated time. It gets 
                you prepared for all kinds of exams. Good luck!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="details"
        className="basic-2"
        style={{ paddingTop: 120, paddingBottom: 60 }}>
        <div className="container">
          <div className="row mobile-reverse" style={{ marginTop: "-60px" }}>
            <div className="col-lg-7" style={{ marginTop: "-40px"}}>
              <div className="text-container">
                <div className="section-title">Game 6</div>
                <h2>The Puzzle Game</h2>
                <p>
                Puzzle game is a kind of drag and drop type select response game that 
                presents a question and the user tries to rearrange the pieces of the 
                puzzle. The game displays the answer in multiple parts, which needs to 
                be rearranged. It might need to be rearranged in a horizontal manner if 
                the answer is short or in vertical manner if it contains various sentences. 
                It is a timed game and the game ends in stipulated time. Learning the drug 
                names, classifications and sequences in medicine is fun with the Puzzle game.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="image-container d-flex justify-content-center">
                <img
                  className="img-fluid box-shadow-game"
                  src="images/puzzle_game.gif"
                  style={{ maxWidth: "60%" }}
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slider-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading">Faculty and Student Testimonials</h2>
              <p className="p-heading">
                You can read below a few testimonials from satisfied students
                and faculty members. We are not sure if we have unsatisfied
                users, do try it out for yourself, we believe you too will
                become a satisfied user of our app.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
