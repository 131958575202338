import "./Blogs.scss";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Paginate from "../Components/Paginate";
import axios from "axios";
import BlogCard from "../Components/BlogCard";
import { BACKEND_URL, BLOG_LIST } from "../constants/medipuzzleApi";

const blogDetail = {
  totalBlogs: 50,
  currentPage: 1,
};

export default function Blogs() {
  const navigate = useNavigate();

  const [blogDetails, setBlogDetails] = useState(blogDetail);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}${BLOG_LIST}`, {})
      .then((response) => {
        if (response?.data) {
          if (response.data.data) {
            const blogs = response?.data?.data;
            let downloadSection;
            if (blogs) {
              downloadSection = blogs.filter(
                (each) =>
                  !each.title.toUpperCase().includes("DOWNLOAD") &&
                  each.is_published
              );
              setBlogs(downloadSection);
            }
          }
        }
      })
      .catch((error) => {});
  }, []);

  const handleBlogClick = (e, blogId) => {
    navigate(`/blogs/${blogId}#disqus_thread`);
  };

  const renderBlogs = () => {
    return blogs.map((blog, index) => {
      return (
        <BlogCard key={index} handleBlogClick={handleBlogClick} blog={blog} />
      );
    });
  };

  // Loading Spinner
  if (blogs.length === 0)
    return (
      <div className="spinner-container">
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div className="blogs" style={{ marginTop: "125px" }}>
      <div className="container">
        <div className="blog-cards row" style={{ minHeight: "400px" }}>
          {renderBlogs()}
        </div>
        {/* <Paginate className="m-4" handlePageChange={handlePageChange} itemsPerPage={4} totalBlogs={blogDetails.totalBlogs} /> */}
      </div>
    </div>
  );
}
